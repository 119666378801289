<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        สต๊อก
        <v-icon>mdi-chevron-right</v-icon>
        การใช้พื้นที่
      </v-card-title>
    </v-card>


    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                วันที่ :
              </v-col>
              <v-col cols="12" md="4" class="pt-1 pb-2">
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      :value="startDate"
                      @change="value => startDate = value"
                      autocomplete="off"
                      label="เริ่มต้น"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      hide-details

                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title scrollable :allowed-dates="allowedDates">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="pt-1 pb-2">
                <v-menu
                  ref="menuEndDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      :value="endDate"
                      @change="value => endDate = value"
                      autocomplete="off"
                      label="สิ้นสุด"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      hide-details

                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" no-title scrollable :allowed-dates="allowedDates">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                ร้านค้า :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  ref="search_shops"
                  v-model="search_shops"
                  :items="shop_list"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn :elevation="1" color="primary" class="pa-2 mt-1" dark @click="shop_space_summary(), tab = 'tab-1'">
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>


    <v-card>
      <v-card-title >
        <v-tabs v-model="tab"  outlined  slider-color="primary">
          <v-tab  href="#tab-1"> การใช้พื้นที่ล่าสุด</v-tab>
          <v-tab  href="#tab-2" style=" pointer-events: none;"> รายละเอียด </v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item  value="tab-1">
            <v-row class="mb-3">
              <v-col cols="12">
                <v-btn dark color="success" @click="dialog_add_shop_space_detail_emty()" class="fn-14"><v-icon left>fa-plus</v-icon>เพิ่มร้านค้าใหม่</v-btn>
              </v-col>
            </v-row>
            <v-data-table
              v-if="shop_space_summary_list.length != 0"
              :headers="shop_space_summary_header"
              :items="shop_space_summary_list"
              item-key="id"
              class="elevation-0 packhai-border-table"
              :mobile-breakpoint="0"
              :items-per-page="10000000"
              hide-default-footer
              >
              <template v-slot:item.num="{ item }">
                <font>{{ formatMoney(shop_space_summary_list.indexOf(item) +1) }}</font>
              </template>
              <template v-slot:item.latestDate="{ item }">
                <font>{{ formatDatePart(item.latestDate) }}</font>
              </template>
              <template v-slot:item.detail="{ item }">
                <a icon @click="shop_space_detail(item)"><v-icon size="18" color="success darken-1">fa-file-alt</v-icon></a>
              </template>

              <template v-slot:item.pallete="{ item }">
                <span v-if="item.pallele != 0">{{ item.pallele }}</span>
              </template>
              <template v-slot:item.rack="{ item }">
                <span v-if="item.rack != 0">{{ item.rack }}</span>
              </template>
              <template v-slot:item.shelf="{ item }">
                <span v-if="item.shelf != 0">{{ item.shelf }}</span>
              </template>
              <template v-slot:item.coolPallele="{ item }">
                <span v-if="item.coolPallele != 0">{{ item.coolPallele }}</span>
              </template>
              <template v-slot:item.coolRack="{ item }">
                <span v-if="item.coolRack != 0">{{ item.coolRack }}</span>
              </template>
              <template v-slot:item.coolShelf="{ item }">
                <span v-if="item.coolShelf != 0">{{ item.coolShelf }}</span>
              </template>

              <template v-slot:item.averagePallele="{ item }">
                <span v-if="item.averagePallele != 0 "> {{ format_price(item.averagePallele ) }}</span>
              </template>
              <template v-slot:item.averageRack="{ item }">
                <span v-if="item.averageRack != 0 "> {{ format_price(item.averageRack ) }}</span>
              </template>
              <template v-slot:item.averageShelf="{ item }">
                <span v-if="item.averageShelf != 0 "> {{ format_price(item.averageShelf ) }}</span>
              </template>
              <template v-slot:item.averageCoolPallele="{ item }">
                <span v-if="item.averageCoolPallele != 0 "> {{ format_price(item.averageCoolPallele ) }}</span>
              </template>
              <template v-slot:item.averageCoolRack="{ item }">
                <span v-if="item.averageCoolRack != 0 "> {{ format_price(item.averageCoolRack ) }}</span>
              </template>
              <template v-slot:item.averageCoolShelf="{ item }">
                <span v-if="item.averageCoolShelf != 0 "> {{ format_price(item.averageCoolShelf ) }}</span>
              </template>
            </v-data-table>

            <!-- <div v-else>
              <v-btn dark color="success" @click="dialog_add_shop_space_detail_emty()" class="fn-14"><v-icon left>fa-plus</v-icon>เพิ่มร้านค้าใหม่</v-btn>
              <br><br>
              <span style="color: red ;" v-if="search_shops.id == null && shop_valid == true"><b>กรุณาเลือกร้านค้า !!</b> </span>
            </div> -->
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" align="left" class="pb-3 px-3">
                    <v-row>
                      <v-col cols="12" md="6" align="left" class="pb-3 px-3"><v-btn dark color="success" @click="dialog_add_shop_space_detail()" class="fn-14"><v-icon left>fa-plus</v-icon>เพิ่ม</v-btn></v-col>
                      <v-col cols="12" md="6" align="right" class="pb-3 px-3">
                        <v-btn @click="excel_place_export()" color="success" outlined><v-icon left>mdi-file-excel</v-icon>
                          export excel
                        </v-btn>
                        <vue-excel-xlsx
                            v-show="false"
                            id="GetExcel"
                            :data="shop_space_detail_list"
                            :columns="excel_shop_space_detail_header"
                            :file-name="'รายงานข้อมูลการใช้พื้นที่'"
                            :sheet-name="'รายงานข้อมูลการใช้พื้นที่'"
                            >
                        </vue-excel-xlsx>

                        <v-btn class="ml-3" color="danger" v-if="space_detail_selected.length > 0" @click="invoice_alert()" outlined>สร้างใบแจ้งหนี้</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="shop_space_detail_header"
                      :items="shop_space_detail_list"
                      class="elevation-0 packhai-border-table"
                      :mobile-breakpoint="0"
                      :items-per-page="10000000"
                      hide-default-footer
                      item-key="id"
                      v-model="space_detail_selected"
                      show-select
                      :single-select="false"
                      >
                      <template v-slot:item.num="{ item }">
                        <font>{{ formatMoney(shop_space_detail_list.indexOf(item) +1) }}</font>
                      </template>
                      <template v-slot:item.date="{ item }">
                        <font>{{ formatDatePart(item.date) }}</font>
                      </template>
                      <template v-slot:item.edit="{ item }">
                        <v-btn v-if="item.invoiceNo == null" icon @click="see_shop_space_detail(item)"><v-icon color="info" size="20">fa-edit</v-icon></v-btn>
                        <v-btn icon v-else><v-icon  size="20">fa-edit</v-icon></v-btn>
                      </template>
                      <template v-slot:item.delete="{ item }">
                        <v-btn  v-if="item.invoiceNo == null" icon @click="dialog_delete_shop_space(item)"><v-icon color="danger" size="20">fa-trash-alt</v-icon></v-btn>
                        <v-btn v-else icon ><v-icon size="20">fa-trash-alt</v-icon></v-btn>
                      </template>
                      <template v-slot:item.shopName="{ item }">
                        <span v-if="data_shop_detail != null">{{ data_shop_detail.shopName }}</span>
                      </template>
                      <template v-slot:item.pallete="{ item }">
                        <span v-if="item.pallete != 0">{{ item.pallete }}</span>
                      </template>
                      <template v-slot:item.rack="{ item }">
                        <span v-if="item.rack != 0">{{ item.rack }}</span>
                      </template>
                      <template v-slot:item.shelf="{ item }">
                        <span v-if="item.shelf != 0">{{ item.shelf }}</span>
                      </template>
                      <template v-slot:item.coolPallete="{ item }">
                        <span v-if="item.coolPallete != 0">{{ item.coolPallete }}</span>
                      </template>
                      <template v-slot:item.coolRack="{ item }">
                        <span v-if="item.coolRack != 0">{{ item.coolRack }}</span>
                      </template>
                      <template v-slot:item.coolShelf="{ item }">
                        <span v-if="item.coolShelf != 0">{{ item.coolShelf }}</span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>


    <v-dialog v-model="dialog_see_shop_space_detail" max-width="600" >
      <v-card elevation="1">
        <v-card-title class="pr-1">
          {{ dialog_title }}
          <v-spacer/>
          <v-btn icon @click="dialog_see_shop_space_detail=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7 pb-0" v-if="shop_space_detail_dict != null">
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>วันที่</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-menu
                ref="menudialogDate"
                v-model="menudialogDate"
                :close-on-content-click="false"
                :return-value.sync="dialogDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    :value="dialogDate"
                    @change="value => dialogDate = value"
                    autocomplete="off"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    hide-details

                  ></v-text-field>
                </template>
                <v-date-picker v-model="dialogDate" no-title scrollable :allowed-dates="allowedDates">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>ร้านค้า</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <!-- <v-select
                readonly
                v-model="search_shops_for_dialog"
                :items="shop_list_for_dialog"
                :item-text="item => item.name"
                :item-value="item => item.id"
                @change="shop_space_detail_dict.shopID = search_shops_for_dialog.id"
                outlined
                dense
                hide-details
                return-object>
              </v-select>  -->
              <v-text-field
                readonly
                v-model="search_shops_for_dialog.name"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>จำนวน พาเลท</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-text-field
                v-model="shop_space_detail_dict.pallete"
                type="number"
                outlined
                dense
                hide-details
                @blur="shop_space_detail_dict.pallete = set_emty_to_0(shop_space_detail_dict.pallete)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>จำนวน Rack</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-text-field
                v-model="shop_space_detail_dict.rack"
                type="number"
                outlined
                dense
                hide-details
                @blur="shop_space_detail_dict.rack = set_emty_to_0(shop_space_detail_dict.rack)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>จำนวน ชั้น</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-text-field
                v-model="shop_space_detail_dict.shelf"
                type="number"
                outlined
                dense
                hide-details
                @blur="shop_space_detail_dict.shelf = set_emty_to_0(shop_space_detail_dict.shelf)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>จำนวน พาเลท ห้องเย็น</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-text-field
                v-model="shop_space_detail_dict.coolPallete"
                type="number"
                outlined
                dense
                hide-details
                @blur="shop_space_detail_dict.coolPallete = set_emty_to_0(shop_space_detail_dict.coolPallete)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>จำนวน Rack ห้องเย็น</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-text-field
                v-model="shop_space_detail_dict.coolRack"
                type="number"
                outlined
                dense
                hide-details
                @blur="shop_space_detail_dict.coolRack = set_emty_to_0(shop_space_detail_dict.coolRack)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>จำนวน ชั้น ห้องเย็น</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-text-field
                v-model="shop_space_detail_dict.coolShelf"
                type="number"
                outlined
                dense
                hide-details
                @blur="shop_space_detail_dict.coolShelf = set_emty_to_0(shop_space_detail_dict.coolShelf)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="12" md="5" lg="4" xl="4">
              <strong>หมายเหตุ</strong> :
            </v-col>
            <v-col cols="12" md="7" lg="8" xl="8" align="right">
              <v-textarea
                v-model="shop_space_detail_dict.remark"
                outlined
                row="4"
                dense
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>


        </v-card-text>
        <v-card-actions class="mx-2"  v-if="shop_space_detail_dict != null">
          <v-spacer/>
          <v-btn v-show="shop_space_detail_dict.id != 0" dark color="primary" class="my-1"  @click="new_shop_space('edit')"><v-icon class="ma-1">mdi-content-save</v-icon>บันทึก</v-btn>
          <v-btn v-show="shop_space_detail_dict.id == 0" dark color="primary" class="my-1"  @click="new_shop_space('add')"><v-icon class="ma-1">mdi-content-save</v-icon>บันทึก</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_createinvoice" max-width="40%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          <!-- ยืนยันการสร้างใบแจ้งหนี้การใช้พื้นที่ (   -->
          <span v-if="data_shop_detail != null">
            {{"ยืนยันการสร้างใบแจ้งหนี้การใช้พื้นที่ ( " + data_shop_detail.shopName + " )"}}
          </span>
          <v-spacer/>
          <v-btn icon @click="dialog_createinvoice=false, data_createinvoice=[]"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7" v-if="data_createinvoice.length > 0">
          <v-row>
            <v-col cols="12" align-self="center">
              <v-data-table
                :headers="createinvoice_header"
                :items="data_createinvoice"
                class="elevation-0 packhai-border-table"
                :mobile-breakpoint="0"
                :items-per-page="10000000"
                hide-default-footer
                item-key="id"
              >
                <template v-slot:item.num="{ item }">
                  <font>{{ formatMoney(data_createinvoice.indexOf(item) +1) }}</font>
                </template>
                <template v-slot:item.date="{ item }">
                  <font>{{ formatDatePart(item.date) }}</font>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="pt-2">
            <v-col cols="6">
              รวมรายการ :
            </v-col>
            <v-col cols="6">
              {{data_createinvoice.length + " รายการ"}}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer/>
          <v-btn dark color="primary" class="my-1" @click="invoice_confirm()">ยืนยัน</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2' 
  import Loading from '@/website/components/Loading'
  import { branchService_dotnet, shopService_dotnet, reportService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning } from '@/website/global_alert'
  import { formatDatePart, FormatInvoice, FormatOrder, formatMoney, isNumberWNoDot, set_format_date_time, format_price, format_vat_type } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,

      navigete: [],

      // เปิด เลือกวันที่
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 8) + '01',
      menuStartDate: false,
      checkStart: true,

      endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuEndDate: false,
      checkEnd: true,
      // ปิด เลือกวันที่


      dialogDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menudialogDate: false,
      checkDialogDate: true,
      // ปิด เลือกวันที่

      search_shops: {'id': null, 'name': ' -- ร้านค้าทั้งหมด --'},
      shop_valid : false,

      search_shops_for_dialog : {'id': null, 'name': ' -- ร้านค้าทั้งหมด --'},
      shop_list_for_dialog : [],

      confirmCreateInvoiceDialog: false,
      tabSelected: null,
      tabList: ['สร้างใบแจ้งหนี้', 'ใบแจ้งหนี้ทั้งหมด'],

      shop_select: null,
      shop_list: [],

      tab: 'tab-1',

      shop_space_summary_header : [
        { text: "No", value: "num", sortable: false, align: 'center', width: "60px"},
        { text: "รายละเอียด", value: "detail", sortable: false, align: 'center', width: "120px"},
        { text: "ร้านค้า", value: "shopName", sortable: false, align: 'left', width: "200px"},
        { text: "พาเลท", value: "pallele", sortable: false, align: 'center', width: "80px"},
        { text: "Rack", value: "rack", sortable: false, align: 'center', width: "80px"},
        { text: "ชั้น", value: "shelf", sortable: false, align: 'center', width: "80px"},
        { text: "พาเลท ห้องเย็น", value: "coolPallele", sortable: false, align: 'center', width: "100px"},
        { text: "Rack ห้องเย็น", value: "coolRack", sortable: false, align: 'center', width: "100px"},
        { text: "ชั้น ห้องเย็น", value: "coolShelf", sortable: false, align: 'center', width: "100px"},
        { text: "ลงข้อมูลล่าสุด", value: "latestDate", sortable: false, align: 'center', width: "120px"},
        { text: "ลงล่าสุดโดย", value: "staffName", sortable: false, align: 'left', width: "150px"},
        { text: "จำนวนวัน", value: "totalDay", sortable: false, align: 'center', width: "100px"},
        { text: "เฉลี่ยพาเลท/วัน", value: "averagePallele", sortable: false, align: 'right', width: "135px"},
        { text: "เฉลี่ย Rack/วัน", value: "averageRack", sortable: false, align: 'right', width: "130px"},
        { text: "เฉลี่ย ชั้น/วัน", value: "averageShelf", sortable: false, align: 'right', width: "130px"},
        { text: "เฉลี่ยพาเลทห้องเย็น/วัน", value: "averageCoolPallele", sortable: false, align: 'right', width: "120px"},
        { text: "เฉลี่ย Rack ห้องเย็น/วัน", value: "averageCoolRack", sortable: false, align: 'right', width: "120px"},
        { text: "เฉลี่ย ชั้น ห้องเย็น/วัน", value: "averageCoolShelf", sortable: false, align: 'right', width: "120px"},
      ],
      shop_space_summary_list : [],

      shop_space_detail_header: [
        { text: "No", value: "num", sortable: false, align: 'center'},
        { text: "แก้ไข", value: "edit", sortable: false, align: 'center'},
        { text: "ลบ", value: "delete", sortable: false, align: 'center'},
        { text: "วันที่", value: "date", sortable: false, align: 'center'},
        { text: "ลูกค้า", value: "shopName", sortable: false, align: 'left'},
        { text: "พาเลท", value: "pallete", sortable: false, align: 'center'},
        { text: "Rack", value: "rack", sortable: false, align: 'center'},
        { text: "ชั้นวาง", value: "shelf", sortable: false, align: 'center'},
        { text: "พาเลท ห้องเย็น", value: "coolPallete", sortable: false, align: 'right'},
        { text: "Rack ห้องเย็น", value: "coolRack", sortable: false, align: 'right'},
        { text: "ชั้น ห้องเย็น", value: "coolShelf", sortable: false, align: 'right'},
        { text: "หมายเหตุ", value: "remark", sortable: false, align: 'left'},
        { text: "สร้างวันที่", value: "date", sortable: false, align: 'center'},
        { text: "สร้างโดย", value: "staffName", sortable: false, align: 'left'},
        { text: "เลขใบแจ้งหนี้", value: "invoiceNo", sortable: false, align: 'left'},
      ],
      shop_space_detail_list : [],
      space_detail_selected : [],
      shop_space_detail_list_for_excel : [],
      space_id_list:[],

      shop_space_detail_dict : null,

      dialog_see_shop_space_detail: false,
      dialog_title : '',

      data_shop_detail: null,

      // excel
      excel_shop_space_detail_header:[
        { label: "วันที่", field: "date" },
        { label: "ลูกค้า", field: "shopName", width: "35"},
        { label: "พาเลท", field: "pallete"},
        { label: "Rack", field: "rack"},
        { label: "ชั้นวาง", field: "shelf"},
        { label: "พาเลท ห้องเย็น", field: "coolPallete"},
        { label: "Rack ห้องเย็น", field: "coolRack"},
        { label: "ชั้น ห้องเย็น", field: "coolShelf"},
        { label: "หมายเหตุ", field: "remark"},
        { label: "สร้างวันที่", field: "date"},
        { label: "สร้างโดย", field: "staffName"},
        { label: "เลขใบแจ้งหนี้", field: "invoiceNo"},

      ],

      // confirm create invoice
      dialog_createinvoice: false,
      data_createinvoice:[],
      createinvoice_header:[{ text: "No", value: "num", sortable: false, align: 'center'},
                            { text: "วันที่", value: "date", sortable: false, align: 'center'}
                           ]

    }),
    async created() {
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      // await this.get_postcode_mapping()
      await this.get_shop_all()

      await this.shop_space_summary()
      // this.getInvoice()
      this.page_loading = false
    },
    computed: {
      computed_search_sent_date() {
        if(this.date_range[0] != null) {
          return this.formatDate(this.date_range)
        } else {
          return null
        }
      },
    },
    watch: {
      startDate: async function(newVal, oldVal) {
        if (new Date(newVal)>=new Date(this.endDate)){
          this.checkStart = false
          this.startDate = oldVal
        }else if(this.checkStart){
          try {
            this.$refs.menuStartDate.save(this.startDate)
          }catch(err){
            console.log(err);
          }
        }else{
          this.checkStart = true
        }
      },
      endDate: async function(newVal, oldVal) {
        if (new Date(newVal)<=new Date(this.startDate) || new Date(newVal)>new Date()){
          this.checkEnd = false
          this.endDate = oldVal
        }else if(this.checkEnd){
          try {
            this.$refs.menuEndDate.save(this.endDate)
          }catch(err){
            console.log(err);
          }
        }else{
          this.checkEnd = true
        }
      },

      dialogDate: async function(newVal, oldVal) {
        if(this.checkDialogDate){
          try {
            this.$refs.menudialogDate.save(this.dialogDate)
          }catch(err){
            console.log(err);
          }
        }else{
          this.checkDialogDate = true
        }
      },
    },
    methods: {
      async get_shop_all () {
        let res1 = await axios.post(shopService_dotnet+'Shop/get-shop-list', {"branchId": localStorage.getItem('Branch_BranchID') },
        { headers: this.header_token})
        this.shop_list = res1.data.result
        this.shop_list = [{ "id": null, "name": "--ทั้งหมด--"}]
        for (var i = 0; i < res1.data.result.length; i++) {
          this.shop_list.push(res1.data.result[i].shop)
        }

        this.shop_list_for_dialog = [...this.shop_list]
        this.shop_list_for_dialog.shift()
      },

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }

      },

      async shop_space_summary () {
        this.loading = true
        let res1 = await axios.post(branchService_dotnet+'Shop/shop-space-summary', {
          "branchID": localStorage.getItem('Branch_BranchID') ,
          "shopID": this.search_shops.id,
          "dateFrom": this.startDate,
          "dateTo": this.endDate
        },
        { headers: this.header_token})
        this.shop_space_summary_list = res1.data.spaceSummary


        this.startDate = res1.data.dateFrom
        this.endDate = res1.data.dateTo

        this.loading = false
      },

      async shop_space_detail (item) {
        this.loading = true
        this.space_detail_selected = []
        let res1 = await axios.post(branchService_dotnet+'Shop/shop-space-detail-list', {
          "branchID": localStorage.getItem('Branch_BranchID') ,
          "shopID": item.shopID,
          "dateFrom": this.startDate,
          "dateTo": this.endDate
        },
        { headers: this.header_token})

        this.data_shop_detail = item
        this.shop_space_detail_list = res1.data.shopSpaceDetailItem

        this.tab = 'tab-2'

        this.loading = false
      },

      async see_shop_space_detail (item) {
        this.loading = true
        let res1 = await axios.post(branchService_dotnet+'Shop/shop-space-detail', {
          "id": item.id
        },
        { headers: this.header_token})
        this.shop_space_detail_dict = null
        if(res1.data != '' && res1.data != null){
          this.shop_space_detail_dict = res1.data
        }


        for (var i = 0; i < this.shop_list_for_dialog.length; i++) {
          if(this.shop_space_detail_dict.shopID == this.shop_list_for_dialog[i].id ) {
            this.search_shops_for_dialog = this.shop_list_for_dialog[i]
            break;
          }
        }

        let date_tmp = this.shop_space_detail_dict.date;
        const date_tmp_split= date_tmp.split("-");

        this.shop_space_detail_dict.date = date_tmp_split[2] + '-' + date_tmp_split[1] + '-' + date_tmp_split[0]
        this.dialogDate =  date_tmp_split[2] + '-' + date_tmp_split[1] + '-' + date_tmp_split[0]
        this.shop_space_detail_dict.staffID = localStorage.getItem('Branch_StaffID'),
        this.shop_space_detail_dict.id = item.id,

        this.dialog_see_shop_space_detail = true
        this.dialog_title = 'แก้ไขรายการ'
        this.loading = false
      },

      async new_shop_space (type) {
        this.loading = true
        if(type == 'edit'){
          this.shop_space_detail_dict.date = this.dialogDate
          let res1 = await axios.post(branchService_dotnet+'Shop/new-shop-space', this.shop_space_detail_dict,
          { headers: this.header_token})
        } else if (type == 'add') {
          // console.log(this.shop_space_detail_dict);
          this.shop_space_detail_dict.id = 0
          this.shop_space_detail_dict.date = this.dialogDate
          let res1 = await axios.post(branchService_dotnet+'Shop/new-shop-space', this.shop_space_detail_dict,
          { headers: this.header_token})
        }

        this.dialog_see_shop_space_detail = false
        if(this.shop_space_summary_list.length == 0){
          this.shop_space_summary()
        } else {
          this.shop_space_detail(this.data_shop_detail)
        }



        this.loading = false
      },

      dialog_add_shop_space_detail_emty () {
        if(this.search_shops.id == null){
          // alert('กรุณาเลือกร้านค้า !!')
          this.$refs.search_shops.focus();
          this.shop_valid = true
          return
        }

        // {{ shop_space_summary_list[0] }}
        // {{ shop_list[0] }}
        if(this.shop_space_summary_list.length > 0){
          for (var ii = 0; ii < this.shop_space_summary_list.length; ii++) {
            if(this.shop_space_summary_list[ii].shopID == this.search_shops.id){
              this.$refs.search_shops.focus();
              this.shop_valid = true
              this.AlertWarning("มีข้อมูลร้านนี้อยู่แล้ว")
              return
            }
          }
        }




        this.dialogDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        this.shop_space_detail_dict = {
          "id": 0,
          "branchID": localStorage.getItem('Branch_BranchID'),
          "shopID": this.search_shops.id,
          "staffID": localStorage.getItem('Branch_StaffID'),
          "date": this.dialogDate,
          "pallete": 0,
          "rack": 0,
          "shelf": 0,
          "coolPallete": 0,
          "coolRack": 0,
          "coolShelf": 0,
          "remark": null
        }

        for (var i = 0; i < this.shop_list_for_dialog.length; i++) {
          if(this.shop_space_detail_dict.shopID == this.shop_list_for_dialog[i].id ) {
            this.search_shops_for_dialog = this.shop_list_for_dialog[i]
            break;
          }
        }

        this.dialog_title = 'เพิ่มรายการ'
        this.dialog_see_shop_space_detail = true

      },

      dialog_add_shop_space_detail () {
        this.dialogDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        this.shop_space_detail_dict = {
          "id": 0,
          "branchID": localStorage.getItem('Branch_BranchID'),
          "shopID": this.data_shop_detail.shopID,
          "staffID": localStorage.getItem('Branch_StaffID'),
          "date": this.dialogDate,
          "pallete": 0,
          "rack": 0,
          "shelf": 0,
          "coolPallete": 0,
          "coolRack": 0,
          "coolShelf": 0,
          "remark": null
        }

        for (var i = 0; i < this.shop_list_for_dialog.length; i++) {
          if(this.shop_space_detail_dict.shopID == this.shop_list_for_dialog[i].id ) {
            this.search_shops_for_dialog = this.shop_list_for_dialog[i]
            break;
          }
        }

        this.dialog_title = 'เพิ่มรายการ'
        this.dialog_see_shop_space_detail = true

      },

      dialog_delete_shop_space (item) {
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ลบการใช้พื้นที่ !!',
          text: 'ต้องการลบข้อมูลการใช้พื้นที่หรือไม่ !!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false

        }).then((result) => {
          if (result.value) {
            this.fonfirm_delete_shop_space(item)
          }
        })
      },

      async fonfirm_delete_shop_space (item) {
        this.loading = true
        let res1 = await axios.post(branchService_dotnet+'Shop/delete-shop-space', {"id": item.id},
        { headers: this.header_token})

        this.shop_space_detail(this.data_shop_detail)
        this.loading = false
      },

      set_emty_to_0 ( data) {
        if(data == '' || data ==null){
          return 0
        } else {
          return data
        }
      },

      async excel_place_export () {
        this.shop_space_detail_list_for_excel = []

        this.shop_space_detail_list_for_excel = [...this.shop_space_detail_list]

        for (var i = 0; i < this.shop_space_detail_list_for_excel.length; i++) {
          this.shop_space_detail_list_for_excel[i].shopName = this.data_shop_detail.shopName
        }
        await document.getElementById("GetExcel").click();
      },

      invoice_alert () {
        var id_list = []
        this.data_createinvoice = []
        for (var i = 0; i < this.space_detail_selected.length; i++) {
          // id_list.push(this.space_detail_selected[i].id)
          if (this.space_detail_selected[i]['invoiceID'] == null) {
            this.space_id_list.push(this.space_detail_selected[i].id)
            this.data_createinvoice.push(this.space_detail_selected[i])
            // if (this.data_createinvoice.length > 0) {
            //   this.data_createinvoice.push(this.space_detail_selected[i])
            // }
            // else{
            //   this.data_createinvoice = this.space_detail_selected[i]
            // }
          }
        }
        this.dialog_createinvoice = true;
      },

      async invoice_confirm () {
        // var id_list = []
        // for (var i = 0; i < this.space_detail_selected.length; i++) {
        //   id_list.push(this.space_detail_selected[i].id)
        // }
        try {
          let res1 = await axios.post(reportService_dotnet+'Invoice/calculate-space-with-id',{
            "staffBranchID": localStorage.getItem('Branch_StaffID'),
            "idList": this.space_id_list
          },
          { headers: this.header_token})
          if (res1.status == 200) {
            this.dialog_createinvoice = false
            this.shop_space_detail(this.data_shop_detail)
            this.AlertSuccess()
          }
        } catch (error) {
          this.AlertWarning("เกิดข้อผิดพลาดการสร้างใบแจ้งหนี้")
        }
      },
      formatDatePart,
      FormatInvoice,
      FormatOrder,
      formatMoney,
      isNumberWNoDot,
      set_format_date_time,
      format_price,
      format_vat_type,
      AlertSuccess,
      AlertWarning,
    }
  }
</script>

<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
  .mobile-none {
    display: ;
  }
  .pc-none {
    display: none;
  }
  >>>.v-select__slot{
    height: 40px;
  }
  @media (max-width: 599px) {
    .mobile-none {
      display: none;
    }
    .pc-none {
      display: ;
    }
  }
</style>
